import { ProductProps } from './product-list/product-list.model'

export const listYou: ProductProps[] = [
  {
    id: 1,
    image: {
      src: `${process.env.NEXT_PUBLIC_IMAGES_PREFIX}/home/home-equity.png?updated=03072022`,
      width: 257,
      height: 150,
      alt: 'Empréstimo com garantia de imóvel'
    },
    link: '/emprestimo-com-garantia-de-imovel',
    title: 'Empréstimo com garantia de imóvel',
    description: 'Tenha seu imóvel como fonte de suas realizações.',
    dataLayer: {
      category: 'Clique',
      action: 'Portfólio',
      label: 'Para Você - Card Home Equity - Botão Saiba mais'
    }
  },
  {
    id: 2,
    image: {
      src: '/images/home/products/construction.png?updated=240917',
      width: 264,
      height: 149,
      alt: 'Financiamento para Construção'
    },
    link: '/financiamento-para-construcao',
    title: 'Financiamento para Construção',
    description: 'Encontre flexibilidade para construir seus projetos.',
    dataLayer: {
      category: 'Clique',
      action: 'Portfólio',
      label: 'Para Você - Card Financiamento Construção - Botão Saiba mais'
    }
  },
  {
    id: 3,
    image: {
      src: '/images/home/products/solar-energy.png?updated=230607',
      width: 309,
      height: 181,
      alt: 'Financiamento para Energia Solar'
    },
    link: '/financiamento-energia-solar',
    title: 'Financiamento para Energia Solar',
    description: 'Reduza seus custos com energia elétrica e invista no futuro.',
    dataLayer: {
      category: 'Clique',
      action: 'Portfólio',
      label: 'Para seu Negócio - Card Financiamento para Energia Solar - Botão Saiba mais'
    }
  },
  {
    id: 4,
    image: {
      src: '/images/home/products/sfi.png?updated=240917',
      width: 264,
      height: 149,
      alt: 'Financiamento Imobiliário (SFI)'
    },
    link: '/financiamento-imobiliario',
    title: 'Financiamento Imobiliário (SFI)',
    description: 'Financie seu imóvel e concretize seus sonhos.',
    dataLayer: {
      category: 'Clique',
      action: 'Portfólio',
      label: 'Para Você - Card SFI - Botão Saiba mais'
    }
  }
]

export const listBusiness: ProductProps[] = [
  {
    id: 1,
    image: {
      src: `${process.env.NEXT_PUBLIC_IMAGES_PREFIX}/home/negocio-home-equity.png?updated=03072022`,
      width: 263,
      height: 152,
      alt: 'Crédito com garantia de imóvel'
    },
    link: '/credito/credito-com-garantia-de-imovel',
    title: 'Crédito com garantia de imóvel',
    description: 'Use o imóvel a seu favor e tire seus planos do papel.',
    dataLayer: {
      category: 'Clique',
      action: 'Portfólio',
      label: 'Para seu Negócio - Card HE - Botão Saiba mais'
    }
  },
  {
    id: 2,
    image: {
      src: '/images/home/products/solar-energy-1.png?updated=230607',
      width: 309,
      height: 181,
      alt: 'Financiamento para Energia Solar'
    },
    link: '/financiamento-energia-solar',
    title: 'Financiamento para Energia Solar',
    description: 'Economize recursos e amplie os benefícios da sua empresa.',
    dataLayer: {
      category: 'Clique',
      action: 'Portfólio',
      label: 'Para seu Negócio - Card Financiamento para Energia Solar - Botão Saiba mais'
    }
  }
]

export const listCondo: ProductProps[] = [
  {
    id: 1,
    image: {
      src: `${process.env.NEXT_PUBLIC_IMAGES_PREFIX}/home/condo.png?updated=03072022`,
      width: 193,
      height: 143,
      alt: 'Empréstimo para condomínios'
    },
    link: '/condo',
    title: 'Empréstimo para condomínios',
    description: 'Renove os condomínios e valorize os imóveis.',
    dataLayer: {
      category: 'Clique',
      action: 'Portfólio',
      label: 'Condomínio - Card Empréstimo para Condomínio - Botão Saiba mais'
    }
  },
  {
    id: 2,
    image: {
      src: '/images/home/products/solar-energy-2.png?updated=230607',
      width: 200,
      height: 151,
      alt: 'Financiamento para Energia Solar'
    },
    link: '/financiamento-energia-solar',
    title: 'Financiamento para Energia Solar',
    description: 'Produza a própria energia do seu condomínio e valorize os imóveis.',
    dataLayer: {
      category: 'Clique',
      action: 'Portfólio',
      label: 'Condomínio - Card Financiamento para Energia Solar - Botão Saiba mais'
    }
  }
]

export const listConsorcio: ProductProps[] = [
  {
    id: 1,
    image: {
      src: `${process.env.NEXT_PUBLIC_IMAGES_PREFIX}/home/consorcio.png`,
      width: 256,
      height: 158,
      alt: 'Carro na garagem'
    },
    link: '/consorcio/',
    title: 'Consórcio',
    description: 'Programe seu futuro e transforme sua história.',
    dataLayer: {
      category: 'Clique',
      action: 'Portfólio',
      label: 'Consórcio - Card Consórcio - Botão Saiba mais'
    }
  },
  {
    id: 2,
    image: {
      src: '/images/home/products/car-consortium.jpg',
      width: 200,
      height: 106,
      alt: 'Carro genérico'
    },
    link: '/consorcio-de-carros',
    title: 'Consórcio de Veículos',
    description: 'O carro que você sempre quis de um jeito que nunca imaginou.',
    dataLayer: {
      category: 'Clique',
      action: 'Portfólio',
      label: 'Consórcio - Card Consórcio de Veículos - Botão Saiba mais'
    }
  }
]
