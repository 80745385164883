import styled from 'styled-components'

import { theme } from 'styles/theme'

const { borderWidths, colors, fontWeights, media, space, fontSizes, transitions } = theme

export const TabList = styled.div`
  height: ${space['spacing-xxl']};
  font-size: ${fontSizes['font-size-caption']};
  overflow-x: auto;
  white-space: nowrap;

  @media (${media.tablet}) {
    overflow: hidden;
  }
`

export const Tab = styled.button`
  all: unset;
  position: relative;
  padding: ${space['spacing-xs']} 0;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: ${space['spacing-md']};
  }

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    height: ${borderWidths['border-stroke-micro']};
    width: 0%;
    border-bottom: ${borderWidths['border-stroke-small']} solid ${colors['blue-text-default']};
    transition:
      width 0.2s ease,
      left 0.2s ease;
  }

  &[data-state='active'] {
    > span {
      font-weight: ${fontWeights['font-weight-bold']};
      color: ${colors['neutral-text-default']};
    }

    &::after {
      left: 25%;
      width: 50%;
    }
  }

  @media (${media.tablet}) {
    > span {
      font-size: ${fontSizes['font-size-body-1']};
    }
  }
`

export const TabContentWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  margin-top: ${space['spacing-lg']};
`

export const Movable = styled.div`
  white-space: nowrap;
  transition: ${transitions.default};
`

export const TabContent = styled.div<{
  active: boolean
}>`
  display: inline-flex;
  white-space: normal;
  width: 100%;
  max-width: 100%;
  height: ${({ active }) => (active ? 'auto' : '0')};
  overflow: hidden;
  transition: ${transitions.default};
`
