import styled from 'styled-components'

import { theme } from 'styles/theme'
const { borderWidths, colors, fonts, fontSizes, fontWeights, lineHeights, media, radii, space, transitions } = theme

export const DynamicForm = styled.div`
  form {
    font-family: ${fonts['font-family-base']} !important;

    & > div > div {
      padding: 0 !important;
    }
  }

  .containerWrapper {
    display: block !important;
  }

  .columnContainer {
    padding: 0 !important;
    width: 100% !important;
  }

  label {
    position: absolute;
    top: 0.625rem;
    left: 0;
    padding: 0;
    max-width: 133%;
    margin: 0;
    color: ${colors['neutral-text-default']} !important;
    font-size: ${fontSizes['font-size-overline']};
    font-weight: ${fontWeights['font-weight-medium']} !important;
    text-transform: uppercase;
    transform-origin: left top;
    cursor: text;
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, top 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

    @media (${media.desktop}) {
      left: ${space['spacing-xs']};
    }

    span {
      display: none;
    }
  }

  input {
    width: 100%;
    outline: none;
    border: none;
    text-indent: 0;
    height: 1.375rem;
    margin-top: ${space['spacing-sm']} !important;
    color: ${colors['neutral-text-default']} !important;
    font-family: ${fonts['font-family-base']} !important;
    font-weight: ${fontWeights['font-weight-regular']};
    font-size: ${fontSizes['font-size-body-1']};

    &::placeholder {
      color: transparent;
    }
  }

  textarea {
    margin-top: ${space['spacing-sm']};
  }
  
  textarea:focus-visible {
    outline: unset;
  }

  div[data-editorblocktype='Text'] span {
    display: block;
    margin-bottom: ${space['spacing-md']};
    color: ${colors['neutral-text-default']};
    font-size: ${fontSizes['font-size-heading-5']};
    font-weight: ${fontWeights['font-weight-medium']};
  }

  div[data-editorblocktype='Field-email'] {
    padding-top: 0 !important;
  }

  .lp-form-field {
    position: relative;
    min-height: ${space['spacing-mega']};
    margin-bottom: ${space['spacing-md']};
    padding: ${space['spacing-xs']} ${space['spacing-xs']} ${space['spacing-xs']} 0;
    border-bottom: ${borderWidths['border-stroke-micro']} solid ${colors['neutral-border-default']};
    transition: all 0.3s ease;

    &:hover {
      border-bottom-color: ${colors['neutral-border-hover']};
      box-shadow: inset 0 -${borderWidths['border-stroke-micro']} 0 0 ${colors['neutral-border-hover']};
    }

    &:focus-within {
      border-bottom-color: ${colors['blue-border-hover']};
      box-shadow: inset 0 -${borderWidths['border-stroke-micro']} 0 0 ${colors['blue-border-hover']};

      label {
        color: ${colors['blue-text-default']} !important;
      }
    }

    @media (${media.desktop}) {
      padding-left: ${space['spacing-xs']};
      border: ${borderWidths['border-stroke-micro']} solid ${colors['neutral-border-default']};
      border-radius: ${radii['corner-radius-sm']};

      &:hover {
        border-color: ${colors['neutral-border-hover']};
        box-shadow: inset 0 0 0 ${borderWidths['border-stroke-micro']} ${colors['neutral-border-hover']};
      }

      &:focus-within {
        border-color: ${colors['blue-border-hover']};
        box-shadow: inset 0 0 0 ${borderWidths['border-stroke-micro']} ${colors['blue-border-hover']};
      }
    }
  }

  .lp-required {
    display: none;
  }

  button.lp-form-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${space['spacing-mega']};
    min-height: ${space['spacing-mega']};
    gap: ${space['spacing-lg']};
    margin-top: ${space['spacing-sm']};
    margin-bottom: ${space['spacing-md']};
    padding: 0 ${space['spacing-sm']};
    border: ${borderWidths['border-stroke-none']};
    border-radius: ${radii['corner-radius-sm']};
    background-color: ${colors['blue-background-strong-default']};
    color: ${colors['blue-text-strong']};
    font-family: ${fonts['font-family-base']};
    font-size: ${fontSizes['font-size-button']};
    font-weight: ${fontWeights['font-weight-medium']};
    line-height: ${lineHeights['font-line-height-lg']};
    text-transform: capitalize;
    transition: ${transitions['default']};
    cursor: pointer;
  }
`

export const DynamicFormNew = styled.div`
  table {
    width: 100%!important;
    
    th, th > div {
      padding: 0!important;
    }

    th > div:not(:first-child) {
      padding-top: ${space['spacing-md']}!important;
    }
  }
`
