import styled from 'styled-components'

import { theme } from 'styles/theme'

const { fontSizes, media, space } = theme

export const ProductsWrapper = styled.div`
  display: inline-flex;
  gap: ${space['spacing-sm']};
  overflow-x: auto;
  white-space: normal;

  h3 {
    display: flex;
    align-items: flex-end;
  }

  @media (${media.desktop}) {
    font-size: ${fontSizes['font-size-body-1']};
  }
`
